import ServiceConfigs from 'service/config';
import { logger } from 'common/utils';
import { apiMutate } from '../../utils/mutation';
import { verifyVoucherMutation } from '../../mutations';
import { Mutation } from '../../types';

type VerifyVoucherType = () => (code: string, isScheduled: boolean, cartId: string) => Promise<Mutation['verifyVoucher']>;

const useVerifyVoucher: VerifyVoucherType = () => (code, isScheduled, cartId) => {
  if (!cartId)
    logger.error({
      error: 'Verify Voucher Error - Attempt to verify voucher with no cartId',
      extraData: { code, isScheduled, cartId },
    });
  return apiMutate({
    mutation: verifyVoucherMutation,
    variables: cartId ? { subdomain: ServiceConfigs.getSubDomain(), code, isScheduled, cartId } : null,
    requestHeaders: { ...ServiceConfigs.getAuthorizationHeader() },
    handler: (res: { verifyVoucher: Mutation['verifyVoucher'] }) => res?.verifyVoucher,
  });
};
export default useVerifyVoucher;
