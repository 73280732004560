import { LANGUAGE_AR } from '../../../common/constants';
import { localizeNumber } from '../../../common/utils';

export const discountTagText = (t, isQuickAddToCartEnabled, lang, highestDiscountedPercentage) => {
  const upToDiscountText = !isQuickAddToCartEnabled ? t('UpToDiscount') : '';
  const isArabic = lang === LANGUAGE_AR;
  const showOffText = isArabic && !isQuickAddToCartEnabled ? '' : t('off');

  return `${upToDiscountText} ${localizeNumber(lang, highestDiscountedPercentage.toFixed())}% ${showOffText}`;
};
