import { pick } from 'ramda';
import { Cart, CartItem } from 'service/types';

import { useCartDetails, useCartSummary, useSelectedCartItems } from '../index';
import { CART_DATA_TYPES, getCartDataType } from './types';

export const useUpdateCartValues = ({
  cartId,
  isCartAuthorized,
}: {
  cartId: string;
  isCartAuthorized: boolean;
}): ((cart?: Partial<Cart>) => Promise<void>) => {
  const { mutate: mutateCartSummary } = useCartSummary({ cartId, isCartAuthorized });
  const { mutate: mutateSelectedCartItems } = useSelectedCartItems({ cartId, isCartAuthorized });
  const { mutate: mutateCartDetails } = useCartDetails({ cartId, isCartAuthorized });

  return async cart => {
    if (cart) {
      const mutateCartValues = () => {
        mutateSelectedCartItems(getCartData(CART_DATA_TYPES.ITEMS, cart) as Partial<CartItem>[], false);
        mutateCartSummary(getCartData(CART_DATA_TYPES.SUMMARY, cart) as Partial<Cart>, false);
        mutateCartDetails(cart, false);
      };
      mutateCartValues();
    } else {
      await Promise.all([mutateCartDetails(), mutateCartSummary(), mutateSelectedCartItems()]);
    }
  };
};

export const getCartData: getCartDataType = (type, data) => {
  switch (type) {
    case CART_DATA_TYPES.SUMMARY:
      return pick(
        [
          'uuid',
          'voucherCode',
          'total',
          'subtotal',
          'cartItemsCount',
          'deliveryFee',
          'discountedAmount',
          'minPreprationTime',
          'deliveryTime',
          'voucherAmount'
        ],
        data,
      );
    case CART_DATA_TYPES.ITEMS:
      return [...pick(['cartItems'], data).cartItems];
    default:
      return data;
  }
};
