import { selectedCartItemsQuery } from '../../queries';
import { QueryHookResponse } from '../../types/internal';
import { CartItem } from '../../types/generated';
import { useQuery } from '../../utils';
import ServiceConfigs from '../../config';

type UseSelectedCartItemsType = ({
  cartId,
  isCartAuthorized,
  initialData,
}?: {
  cartId?: string;
  isCartAuthorized?: boolean;
  initialData?: Array<CartItem>;
}) => QueryHookResponse<Array<Partial<CartItem>>>;

const useSelectedCartItems: UseSelectedCartItemsType = ({ cartId, isCartAuthorized, initialData } = {}) => {
  const fetchValidCart = !isCartAuthorized || (!!ServiceConfigs.getUserToken() && isCartAuthorized);

  return useQuery<Array<Partial<CartItem>>>({
    query: cartId ? selectedCartItemsQuery : null,
    variables: cartId && fetchValidCart ? { cartId } : null,
    handler: (res: any) => res?.cart?.cartItems,
    requestHeaders: isCartAuthorized ? { ...ServiceConfigs.getAuthorizationHeader() } : {},
    config: { initialData },
  });
};

export default useSelectedCartItems;
