import { SWRConfiguration } from 'swr';

import { useGlobalOrderTypeAndTime } from 'common/hooks';
import { handleScheduledTime } from 'modules/productsModule/utils';
import { cartDetailsQuery } from 'service/queries';
import { QueryHookResponse } from 'service/types/internal';
import { Cart } from 'service/types/generated';
import { useQuery } from 'service/utils';
import ServiceConfigs from 'service/config';

type UseCartDetailsType = ({
  cartId,
  isCartAuthorized,
  initialData,
  config,
}?: {
  cartId?: string;
  isCartAuthorized?: boolean;
  initialData?: Cart;
  config?: SWRConfiguration;
}) => QueryHookResponse<Partial<Cart>>;

const useCartDetails: UseCartDetailsType = ({ cartId, isCartAuthorized, initialData, config } = {}) => {
  const [globalOrderTypeAndTime] = useGlobalOrderTypeAndTime();
  const { fulfillmentTimeType, scheduleSlotInterval } = globalOrderTypeAndTime || {};
  const scheduledTime = handleScheduledTime(fulfillmentTimeType, scheduleSlotInterval);
  const fetchValidCart = !isCartAuthorized || (!!ServiceConfigs.getUserToken() && isCartAuthorized);

  return useQuery<Partial<Cart>>({
    query: cartDetailsQuery,
    variables:
      cartId && fetchValidCart
        ? { cartId, ...(!!scheduledTime && { scheduledTime }), subdomain: ServiceConfigs.getSubDomain() }
        : null,
    handler: (res: any) => res.cart,
    config: { initialData, ...config },
    requestHeaders: isCartAuthorized ? { ...ServiceConfigs.getAuthorizationHeader() } : {},
  });
};

export default useCartDetails;
