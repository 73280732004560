import { gql } from 'graphql-request';
import fragmentCart from 'service/fragments/cartFragment';

const cartDetailsQuery = gql`
  query ($cartId: String!, $subdomain: String!, $scheduledTime: ISO8601DateTime) {
    cart(cartId: $cartId, subdomain: $subdomain) {
      ...returnedCart
    }
  }
  ${fragmentCart}
`;

export default cartDetailsQuery;
