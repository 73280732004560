import { Cart, CartItemOptions, DeliveryZoneMs, Scalars } from 'service/types/generated';
import { useAddItemToCart, useInitializeCart } from 'service/hooks';
import ServiceConfigs from 'service/config';
import { normalizeFulfillmentMode } from 'common/utils';
import {
  useGlobalInitializingCart,
  useGlobalLoadingCart,
  useGlobalOrderTypeAndTime,
  usePersistedAppliedVoucherCode,
  usePersistedCartData,
  usePersistedOrderMode,
  usePersistedSelectedCoordinates,
  usePersistedUserAddressId,
} from 'common/hooks';
import { handleScheduledTime } from 'modules/productsModule/utils';
import { FULFILLMENT_MODES } from 'common/constants';

type AddToCartType = ({
  menuItemId,
  variantQuantity,
  variantId,
  options,
  notes,
}: {
  menuItemId: string;
  variantQuantity: number;
  variantId: Scalars['ID'];
  options?: Array<CartItemOptions>;
  notes?: string;
}) => Promise<void | Partial<Cart> | { hasError: boolean }>;

type UseAddToCartType = ({
  branchId,
  deliveryType,
  areaId,
  deliveryZone,
}: {
  branchId: Scalars['ID'];
  deliveryType: string;
  areaId?: Scalars['ID'];
  deliveryZone?: DeliveryZoneMs;
}) => AddToCartType;

/**
 * A custom hook used to add an item to the user cart
 *
 *  * If the user has an initialized cart already then it will add the item to the same cart
 *
 *  * if the user don't have an initialized cart it will initialize the cart, persist the cart id then add the item to this cart
 *
 *  @param Object {{branchId, deliveryType, areaId, deliveryZone}}
 *  @returns Function addItemToCart
 * */

const useAddToCart: UseAddToCartType = ({ branchId, deliveryType, areaId, deliveryZone }) => {
  const [cartData, setCartData] = usePersistedCartData();
  const [, setLoadingCart] = useGlobalLoadingCart();
  const [, setInitializingCart] = useGlobalInitializingCart();
  const [globalOrderTypeAndTime] = useGlobalOrderTypeAndTime();
  const { fulfillmentTimeType, scheduleSlotInterval } = globalOrderTypeAndTime || {};
  const scheduledTime = handleScheduledTime(fulfillmentTimeType, scheduleSlotInterval);
  const [selectedCoords] = usePersistedSelectedCoordinates();
  const useDeliveryzonesMs = ServiceConfigs?.getUseDeliveryzonesMs();
  const [mode] = usePersistedOrderMode();

  const addItemToCart = useAddItemToCart({ ...cartData });
  const [appliedVoucherCode] = usePersistedAppliedVoucherCode();
  const [persistedUserAddressId] = usePersistedUserAddressId();
  const initializeCart = useInitializeCart();

  const initCart = async () => {
    if (!cartData?.cartId) {
      setInitializingCart(true);
      const updateCartVars = {
        branchId,
        deliveryType: normalizeFulfillmentMode(deliveryType),
        ...(useDeliveryzonesMs && !!deliveryZone && mode === FULFILLMENT_MODES.DELIVERY
          ? {
              deliveryZone: {
                ...deliveryZone,
                branchId: deliveryZone?.branchReferenceId?.toString(),
                branchReferenceId: undefined,
                restaurantId: deliveryZone?.restaurantReferenceId?.toString(),
                restaurantReferenceId: undefined,
                geoShape: JSON.stringify(deliveryZone?.geoShape),
                userLat: selectedCoords?.lat,
                userLng: selectedCoords?.lng,
              },
            }
          : { areaId }),
        ...(!!appliedVoucherCode && {
          voucherCode: appliedVoucherCode,
        }),
        ...(persistedUserAddressId && {
          addressId: persistedUserAddressId,
        }),
      };
      const cartIdentifier = await initializeCart(updateCartVars);
      const initializedCart = {
        cartId: cartIdentifier,
        isCartAuthorized: !!ServiceConfigs.getUserToken(),
        justInitialized: true,
      };
      return initializedCart;
    }
    return { ...cartData, justInitialized: false };
  };

  const addToCart: AddToCartType = async ({ menuItemId, variantQuantity, variantId, options, notes }) => {
    setLoadingCart(true);
    const { justInitialized, ...initializedCart } = await initCart();
    const response = await addItemToCart({
      ...(!!scheduledTime && { scheduledTime }),
      menuItemId,
      variantQuantity,
      variantId,
      options,
      notes,
      ...initializedCart,
    });
    if (justInitialized) {
      setCartData(initializedCart);
      setInitializingCart(false);
    }
    setLoadingCart(false);
    return response;
  };

  return addToCart;
};

export default useAddToCart;
