import { localizeNumber } from 'common/utils';

export const translateDisplayPrice = (displayPrice, t, lang, currency, isArabic) => {
  const isPriceOnSelection = displayPrice === 'price on selection';

  const displayPriceLocalized = displayPrice
    .split('-')
    .map(k => localizeNumber(lang, k))
    .join(' - ');

  if (isPriceOnSelection) return t('price on selection');

  const displayPriceWithCurrency = isArabic ? `${displayPriceLocalized} ${currency}` : `${currency} ${displayPriceLocalized}`;

  return displayPriceWithCurrency;
};
