import { Interval } from 'luxon';
import { ORDER_TIME_TYPES } from '../../../common/constants';

export const handleScheduledTime = (fulfillmentTimeType: string, scheduleSlotInterval: Interval): string | null => {
  const isOrderScheduled = fulfillmentTimeType === ORDER_TIME_TYPES.SCHEDULE;
  const slotStartISO = isOrderScheduled && scheduleSlotInterval?.start.toUTC().toISO();

  const scheduledTime = slotStartISO ? `${slotStartISO}` : null;

  return scheduledTime;
};
