import { Translate } from 'next-translate';
import { Product, ProductStockData, Setting } from 'service/types';
import { translateProductAvailability, getAvailabilityTypeAttributes } from '.';
import { LOW_STOCK_MESSAGE_TYPE } from '../../../common/constants';

type itemAvailabilityInfoType = ({
  t,
  stockData,
  productFulfillmentMode,
  mode,
  stockThresholdSettings,
  shouldInvalidateProduct,
  lang,
}: {
  t: Translate;
  mode: string;
  stockData?: Omit<ProductStockData, '__typename'>;
  productFulfillmentMode: Product['fulfillmentMode'];
  stockThresholdSettings: Partial<Setting>;
  shouldInvalidateProduct: boolean;
  lang: string;
}) => {
  availabilityText: string;
  availabilityTagVariant: string;
  unavailabilityReason: string;
  trackingType: string;
  stockCount: number;
  isLowInStock: boolean;
};

export const itemAvailabilityInfo: itemAvailabilityInfoType = ({
  t,
  stockData,
  productFulfillmentMode,
  mode,
  stockThresholdSettings,
  shouldInvalidateProduct,
  lang,
}) => {
  const { unavailable, trackingType, stockCount } = stockData || {};

  const { unavailabilityReason, isLowInStock, availabilityTagVariant } = getAvailabilityTypeAttributes({
    productAvailabilityMode: productFulfillmentMode,
    selectedMode: mode,
    unavailable: !!unavailable,
    stockCount,
    trackingType,
    stockThresholdSettings,
  });

  const availabilityText = translateProductAvailability({
    t,
    mode,
    stockCount,
    unavailabilityReason,
    lowStockMessage: shouldInvalidateProduct
      ? LOW_STOCK_MESSAGE_TYPE.LESS_THAN_MIN_LEFT
      : stockThresholdSettings?.lowStockMessage,
    trackingType,
    isLowInStock,
    lang,
  });
  return { availabilityText, availabilityTagVariant, unavailabilityReason, trackingType, stockCount, isLowInStock };
};
