import { cartSummaryQuery } from '../../queries';
import { QueryHookResponse } from '../../types/internal';
import { Cart } from '../../types/generated';
import { useQuery } from '../../utils';
import ServiceConfigs from '../../config';

type UseCartSummaryType = ({
  cartId,
  isCartAuthorized,
  initialData,
}?: {
  cartId?: string;
  isCartAuthorized?: boolean;
  initialData?: Cart;
}) => QueryHookResponse<Partial<Cart>>;

const useCartSummary: UseCartSummaryType = ({ cartId, isCartAuthorized, initialData } = {}) => {
  const fetchValidCart = !isCartAuthorized || (!!ServiceConfigs.getUserToken() && isCartAuthorized);

  return useQuery<Partial<Cart>>({
    query: cartSummaryQuery,
    variables: cartId && fetchValidCart ? { cartId, subdomain: ServiceConfigs.getSubDomain() } : null,
    requestHeaders: isCartAuthorized ? { ...ServiceConfigs.getAuthorizationHeader() } : {},
    handler: (res: Record<string, Partial<Cart>>) => res?.cart,
    config: { initialData },
  });
};

export default useCartSummary;
