import { gql } from 'graphql-request';

const selectedCartItemsQuery = gql`
  query($cartId: String!) {
    cart(cartId: $cartId) {
      cartItems {
        id
        menuItem {
          uuid
        }
        variantQuantity
      }
    }
  }
`;

export default selectedCartItemsQuery;
