import { gql } from 'graphql-request';

const initializeCartMutation = gql`
  mutation (
    $branchId: ID!
    $deliveryType: String!
    $subdomain: String!
    $areaId: ID
    $deliveryZone: DeliveryZoneMs
    $voucherCode: String
    $addressId: ID
    $isFromOrderfast: Boolean
    $origin: String
  ) {
    createCart(
      branchId: $branchId
      deliveryType: $deliveryType
      subdomain: $subdomain
      areaId: $areaId
      deliveryZone: $deliveryZone
      voucherCode: $voucherCode
      addressId: $addressId
      isFromOrderfast: $isFromOrderfast
      origin: $origin
    ) {
      uuid
    }
  }
`;

export default initializeCartMutation;
