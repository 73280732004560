import { Cart } from 'service/types/generated';
import { logger } from 'common/utils';
import { initializeCartMutation } from '../../mutations';
import { apiMutate } from '../../utils/mutation';
import ServiceConfigs from '../../config';
import { CreateCartVarsType } from './types';

type UseInitializeCartType = () => (variables: CreateCartVarsType) => Promise<Cart['uuid']>;

const useInitializeCart: UseInitializeCartType = () => variables => {
  const { branchId } = variables;
  if (!branchId)
    logger.error({ error: 'Initialize Cart Error - Attempt to initialize cart with no branchId', extraData: variables });

  return apiMutate({
    mutation: initializeCartMutation,
    variables: branchId
      ? {
          ...variables,
          origin: 'storefront',
          subdomain: ServiceConfigs.getSubDomain(),
          isFromOrderfast: !!ServiceConfigs.getOrderFastStore(),
        }
      : null,
    requestHeaders: { ...ServiceConfigs.getAuthorizationHeader() },
    handler: (res: any) => res.createCart.uuid,
  }).then(cartId => cartId);
};
export default useInitializeCart;
