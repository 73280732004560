export const trackTiktokEvents = (eventName, eventValues, userDataValues) => {
  if (typeof (window as any).ttq !== 'undefined') {
    (window as any).ttq.identify({
      ...userDataValues,
    });
    (window as any).ttq.track(eventName, {
      ...eventValues,
    });
  }
};
