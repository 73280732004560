export const verifyVoucherMutation = `
    mutation (
        $code: String!
        $isScheduled: Boolean!
        $subdomain: String!
        $cartId: ID!
      ) {
        verifyVoucher(
          code: $code
          isScheduled: $isScheduled
          subdomain: $subdomain
          cartId: $cartId
        ) {
          valid
          code
        }
      }
`;
